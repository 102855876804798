module.exports = require("deepmerge")(require("./config.default.js"), {
  version: {
    codepushDeploymentVersion: {
      iOS: "",
      android: ""
    }
  },
  native: {
    appleTeamId: "2BAP3P29V2",
    name: {
      default: "BS Lyon_D"
    },
    id: "com.mobilespot.dsl.dev",
    playStoreUrl: ''
  },
  codePush: {
    android: {
      APP_SECRET: "",
      CodePushDeploymentKey: ""
    },
    iOS: {
      APP_SECRET: "",
      CodePushDeploymentKey: ""
    }
  },
  web: {
    url: "bsl-dev.mobile-spot.com",
    deploy: {
      host: "kim1",
      user: "www",
      path: "/home/www/standingevt/bsl/bsl2021"
    }
  }
});